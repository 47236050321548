export const branchOfficeAdapter = (branchOffice) => {
  return {
    id: branchOffice.id,
    name: branchOffice.name,
    address: branchOffice.address,
    status: branchOffice.status,
    createdAt: branchOffice.created_at,
    updatedAt: branchOffice.updated_at,
    deletedAt: branchOffice.deleted_at,
  };
};
