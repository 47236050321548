import { propertyTypeTransformResponse as transformResponse } from "./transforms";
import Http from "./Http";
const PREFIX = "property-types";

export function getPropertyTypes(config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal, transformResponse };
  const call = Http.get(PREFIX, config);

  return { call, controller };
}

export function createPropertyType(formData, config) {
  return Http.post(PREFIX, formData, config);
}

export function updatePropertyType(id, formData, config) {
  return Http.put(`${PREFIX}/${id}`, formData, config);
}

export function checkPropertyTypeNameIsUnique(config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal };
  const call = Http.get(`${PREFIX}/name-unique`, config);

  return { call, controller };
}
