import { operationTypeTransformResponse as transformResponse } from "./transforms";
import Http from "./Http";
const PREFIX = "operation-types";

export function getOperationTypes(config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal, transformResponse };
  const call = Http.get(PREFIX, config);

  return { call, controller };
}
