import { constants, enums, helpers, notifications } from '@/utilities'
import vClickOutside from 'v-click-outside'
import Vuelidate from 'vuelidate'
import {
	Autocomplete,
	Button,
	ConfigProgrammatic,
	Checkbox,
	Dialog,
	Dropdown,
	Field,
	Icon,
	Image,
	Input,
	Loading,
	Message,
	Modal,
	Notification,
	Progress,
	Radio,
	Select,
	Slider,
	Snackbar,
	Steps,
	Switch,
	Table,
	Tabs,
	Tag,
	Taginput,
	Tooltip,
	Upload,
} from 'buefy'
ConfigProgrammatic.setOptions({
	defaultFieldLabelPosition: 'on-border',
	defaultInputAutocomplete: 'off',
})

const global = {
	install(Vue) {
		Vue.use(vClickOutside)
		Vue.use(Vuelidate)

		Vue.use(Autocomplete)
		Vue.use(Button)
		Vue.use(Checkbox)
		Vue.use(Dialog)
		Vue.use(Dropdown)
		Vue.use(Field)
		Vue.use(Icon)
		Vue.use(Image)
		Vue.use(Input)
		Vue.use(Loading)
		Vue.use(Message)
		Vue.use(Modal)
		Vue.use(Notification)
		Vue.use(Progress)
		Vue.use(Radio)
		Vue.use(Select)
		Vue.use(Slider)
		Vue.use(Snackbar)
		Vue.use(Steps)
		Vue.use(Switch)
		Vue.use(Table)
		Vue.use(Tabs)
		Vue.use(Tag)
		Vue.use(Taginput)
		Vue.use(Tooltip)
		Vue.use(Upload)

		Vue.prototype.$constants = Object.freeze(constants)
		Vue.prototype.$enums = Object.freeze(enums)
		Vue.prototype.$helpers = Object.freeze(helpers)
		Vue.prototype.$notifications = Object.freeze(notifications)

		// ***** COMPONENTS ***** //
		Vue.component('BaseEditButton', () => import('@/components/BaseEditButton.vue'))
		Vue.component('BaseActivateButton', () => import('@/components/BaseActivateButton.vue'))
		Vue.component('BaseDeleteButton', () => import('@/components/BaseDeleteButton.vue'))
		Vue.component('BaseDashboardContent', () => import('@/components/BaseDashboardContent.vue'))
		Vue.component('BaseFormSection', () => import('@/components/BaseFormSection.vue'))
		Vue.component('BaseButtonsContent', () => import('@/components/BaseButtonsContent.vue'))
		Vue.component('BaseFluidContainer', () => import('@/components/BaseFluidContainer.vue'))
	},
}

export default global
