export const dashboardRoutes = {
  path: "/dashboard",
  meta: { requireAuth: true },
  component: () => import("@/views/DashboardView.vue"),
  children: [
    {
      path: "",
      name: "dashboard-home",
      component: () => import("@/views/DashboardHome.vue"),
      meta: { icon: "cards-variant", title: "Inicio" },
    },
    {
      path: "property/new",
      name: "property-new",
      component: () => import("@/views/PropertyNewAndEdit.vue"),
      meta: { icon: "home-plus", title: "Nuevo inmueble" },
    },
    {
      path: "property/:id/edit",
      name: "property-edit",
      component: () => import("@/views/PropertyNewAndEdit.vue"),
      meta: { icon: "home-edit", title: "Editar inmueble" },
    },
    {
      path: "property/:id/details",
      name: "property-details",
      component: () => import("@/views/PropertyDetails.vue"),
      meta: { icon: "home-analytics", title: "Mas detalles" },
    },
    {
      path: "property/management",
      name: "property-management",
      component: () => import("@/views/PropertyManagement.vue"),
      meta: { icon: "home-search", title: "Gestion de inmuebles" },
    },
    {
      path: "potential-customer/new",
      name: "potential-customer-new",
      component: () => import("@/views/PotentialCustomerNewAndEdit.vue"),
      meta: {
        icon: "human-male-board",
        title: "Nuevo cliente potencial",
      },
    },
    {
      path: "potential-customer/:id/edit",
      name: "potential-customer-edit",
      component: () => import("@/views/PotentialCustomerNewAndEdit.vue"),
      meta: {
        icon: "human-male-board",
        title: "Editar cliente potencial",
      },
    },
    {
      path: "potential-customer/management",
      name: "potential-customer-management",
      component: () => import("@/views/PotentialCustomerManagement.vue"),
      meta: { icon: "human-queue", title: "Gestion de clientes potenciales" },
    },
    {
      path: "property-type/management",
      name: "property-type-management",
      component: () => import("@/views/PropertyTypeManagement.vue"),
      meta: { icon: "domain", title: "Gestion tipos de inmuebles" },
    },
    {
      path: "branch-office/management",
      name: "branch-office-management",
      component: () => import("@/views/BranchOfficeManagement.vue"),
      meta: {
        icon: "home-group",
        title: "Gestion de Sucursales",
        requireBeAdmin: true,
      },
    },
    {
      path: "employee/management",
      name: "employee-management",
      component: () => import("@/views/EmployeeManagement.vue"),
      meta: {
        icon: "account-group",
        title: "Gestion del personal",
        requireBeAdmin: true,
      },
    },
    {
      path: "my-profile",
      name: "profile",
      component: () => import("@/views/ProfileView.vue"),
      meta: { icon: "account-details", title: "Mi perfil" },
    },
  ],
};
