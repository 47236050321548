import { propertyTypeAdapter } from "@/services/adapters";

export function propertyTypeTransformResponse(data) {
  data = JSON.parse(data);
  if (data?.propertyType)
    data.propertyType = propertyTypeAdapter(data.propertyType);
  if (data?.propertyTypes)
    data.propertyTypes = data.propertyTypes.map((propertyType) =>
      propertyTypeAdapter(propertyType)
    );
  return data;
}
