import { dashboardRoutes } from "./dashboard.routes";
import { webSitieRoutes } from "./web-sitie.routes";

const routes = [
  webSitieRoutes,
  dashboardRoutes,
  {
    path: "/authentication",
    name: "auth",
    component: () => import("@/views/AuthenticationView.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "not-found",
    component: () => import("@/views/NotFound.vue"),
  },
];

export default routes;
