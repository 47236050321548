export function convertLowbarsToSpace(string) {
  if (typeof string !== "string")
    throw new Error("value is not of type string");
  return string.toString().replace(/_/g, " ");
}

export function convertSpacesToLowbar(string) {
  if (typeof string !== "string")
    throw new Error("value is not of type string");
  return string.toString().replace(/ /g, "_");
}

export function capitalizedFirstLetter(string) {
  if (typeof string !== "string")
    throw new Error("value is not of type string");
  const firstLetter = string.toString().charAt(0).toUpperCase();
  return `${firstLetter}${string.toString().slice(1).toLowerCase()}`;
}

export function deepClone(toClone) {
  return JSON.parse(JSON.stringify(toClone));
}

export function formatNumber(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return Number(value).toLocaleString("en-US");
}

export function formatDate(value) {
  const date = new Date(value);
  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  return `${date.getDate()}/${
    monthNames[date.getMonth()]
  }/${date.getFullYear()}`;
}

export function isEmpty(value) {
  if (Array.isArray(value)) return value.length === 0;
  if (typeof value === "object" && value !== null) {
    return Object.keys(value).length === 0;
  }
  return [false, 0, null, "", undefined].includes(value);
}

export function sortObject(object) {
  if (typeof object !== "object")
    throw new Error("value is not of type object");

  const orderedObject = {};
  Object.keys(object)
    .sort()
    .forEach(function (key) {
      orderedObject[key] = object[key];
    });
  return orderedObject;
}
