import { branchOfficeAdapter } from "@/services/adapters";

export function branchOfficeTransformResponse(data) {
  data = JSON.parse(data);

  if (data?.branchOffice)
    data.branchOffice = branchOfficeAdapter(data.branchOffice);
  if (data?.branchOffices)
    data.branchOffices = data.branchOffices.map((branchOffice) =>
      branchOfficeAdapter(branchOffice)
    );

  return data;
}
