import authStore from "./auth.store";
import propertyStore from "./property.store";
import resourceStore from "./resource.store";
import potentialCustomerStore from "./potential-customer.store";

const modules = {
  authStore,
  potentialCustomerStore,
  propertyStore,
  resourceStore,
};

export default modules;
