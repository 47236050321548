import {
  potentialCustomerTransformResponse as transformResponse,
  potentialCustomerTransformRequest as transformRequest,
} from "./transforms";
import Http from "./Http";
const PREFIX = "potential-customers";

export function getPotentialCustomerById(id, config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal, transformResponse };
  const call = Http.get(`${PREFIX}/${id}`, config);

  return { call, controller };
}

export function getPotentialCustomers(search, config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal, transformResponse };
  const call = Http.post(`${PREFIX}/search`, search, config);

  return { call, controller };
}

export function createPotentialCustomer(formData, config) {
  config = { ...config, transformRequest };
  return Http.post(PREFIX, formData, config);
}

export function updatePotentialCustomer(id, formData, config) {
  config = { ...config, transformRequest };
  return Http.put(`${PREFIX}/${id}`, formData, config);
}

export function deletePotentialCustomer(id) {
  return Http.delete(`${PREFIX}/${id}`);
}
