import VuexPersistence from "vuex-persist";
import SecureLS from "secure-ls";
const ls = new SecureLS();

const storage = {
  getItem: (key) => ls.get(key),
  setItem: (key, value) => ls.set(key, value),
};

const reducer = (state) => ({
  authStore: {
    isAuth: state.authStore.isAuth,
    token: state.authStore.token,
    user: state.authStore.user,
  },
});

function filter(mutation) {
  return [
    "authStore/setToken",
    "authStore/setIsAuth",
    "authStore/setUser",
  ].includes(mutation.type);
}

const authPersistence = new VuexPersistence({
  key: "auth",
  storage,
  reducer,
  filter,
});

export default authPersistence.plugin;
