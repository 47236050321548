import { propertyAdapter } from "@/services/adapters";

export function propertyTransformResponse(data) {
  data = JSON.parse(data);
  if (data?.property) data.property = propertyAdapter(data.property);
  if (data?.properties)
    data.properties = data.properties.map((property) =>
      propertyAdapter(property)
    );
  return data;
}

export function propertyTransformRequest(data) {
  return JSON.stringify({
    branch_office_id: data.branchOfficeId,
    operation_type_id: data.operationTypeId,
    property_type_id: data.propertyTypeId,
    zone_id: data.zoneId,
    owner_name: data.ownerName,
    cell_numbers: data.cellNumbers,
    address: data.address,
    reference_area: data.referenceArea,
    payment_type: data.paymentType,
    economic_activity: data.economicActivity,
    real_estate_price: data.realEstatePrice,
    owner_price: data.ownerPrice,
    currency: data.currency,
    guarantee_price: data.guaranteePrice,
    commission: data.commission,
    video_url: data.videoUrl,
    description: data.description,
    notes: data.notes,
    features: data.features,
  });
}
