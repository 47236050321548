import { employeeAdapter } from "@/services/adapters";

export const userAdapter = (user) => {
  return {
    id: user.id,
    photo: user.photo,
    email: user.email,
    isAdmin: user.is_admin,
    status: user.status,
    createdAt: user.created_at,
    updatedAt: user.updated_at,
    employee: user?.employee && employeeAdapter(user.employee),
  };
};
