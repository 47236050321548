import authPersistence from "./auth.persistence";
import potentialCustomerPersistence from "./potential-customer.persistence";
import propertyPersistence from "./property.persistence";
import resourcePersistence from "./resource.persistence";

export default [
  authPersistence,
  potentialCustomerPersistence,
  propertyPersistence,
  resourcePersistence,
];
