import {
  employeeTransformResponse as transformResponse,
  employeeUserTransformRequest as transformRequest,
} from "./transforms";

import Http from "./Http";
const PREFIX = "employees";

export function getEmployees(config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal, transformResponse };
  const call = Http.get(PREFIX, config);

  return { call, controller };
}

export function createEmployeeAndUser(formData, config) {
  config = { ...config, transformRequest };
  return Http.post(`${PREFIX}/and-user`, formData, config);
}

export function updateEmployeeAndUser(id, formData, config) {
  config = { ...config, transformRequest };
  return Http.put(`${PREFIX}/${id}/and-user`, formData, config);
}

export function checkEmployeeIdentityCardIsUnique(config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal };
  const call = Http.get(`${PREFIX}/identity-card-unique`, config);

  return { call, controller };
}
