import { NotificationProgrammatic as Notification } from "buefy";

const initialConfig = {
  hasIcon: true,
  duration: 3500,
  closable: false,
  progressBar: true,
};

export const successful = ({ message }) => {
  return Notification.open({
    ...initialConfig,
    message,
    iconSize: "is-small",
    type: "is-success",
  });
};

export const error = ({ message }) => {
  return Notification.open({
    ...initialConfig,
    message,
    iconSize: "is-small",
    type: "is-danger",
  });
};

export const validationError = () => {
  const validationErrorMessage =
    "Verifique los campos de acuerdo a su requerimiento, lo encontrara de color rojo.";

  return Notification.open({
    ...initialConfig,
    message: validationErrorMessage,
    type: "is-warning",
  });
};
