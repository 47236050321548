import VuexPersistence from "vuex-persist";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

const storage = {
  getItem: (key) => ls.get(key),
  setItem: (key, value) => ls.set(key, value),
};

const reducer = (state) => ({
  resourceStore: {
    branchOffices: state.resourceStore.branchOffices,
    operationTypes: state.resourceStore.operationTypes,
  },
});

function filter(mutation) {
  return [
    "resourceStore/setBranchOffices",
    "resourceStore/setOperationTypes",
  ].includes(mutation.type);
}

const resourcePersistence = new VuexPersistence({
  key: "resource",
  storage,
  reducer,
  filter,
});

export default resourcePersistence.plugin;
