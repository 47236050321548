import store from "@/store";

export const requestInterceptor = (config) => {
  const authToken = store.state.authStore.token;
  if (authToken != null)
    config.headers.common["Authorization"] = `Bearer ${authToken}`;
  return config;
};

export const requestInterceptorError = (error) => {
  return Promise.reject(error);
};
