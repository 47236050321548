import { getProperties } from "@/services";
import { enums, helpers } from "@/utilities";

const initialSearch = () => ({
  branchOfficeId: null,
  code: "",
  currency: null,
  employeeId: null,
  features: {},
  onlyCommission: false,
  operationTypeId: null,
  ownerName: "",
  page: 1,
  paymentTypes: [
    enums.paymentTypes.COUNTED,
    enums.paymentTypes.FINANCING,
    enums.paymentTypes.DIRECT_CREDIT,
  ],
  priceMax: null,
  priceMin: null,
  propertyTypeId: null,
  sortBy: ["id", "desc"],
  status: enums.propertyStatuses.AVAILABLE,
  zones: [],
});
const initialAutocompleteEmployee = () => ({
  name: "",
});
const initialAutocompletePropertyType = () => ({
  form: [],
  name: "",
});
const initialAutocompleteZones = () => ({
  zones: [],
});

const state = {
  fetchController: null,
  inProcess: false,
  pagination: {},
  properties: [],
  search: initialSearch(),
  searchAutocompleteEmployee: initialAutocompleteEmployee(),
  searchAutocompletePropertyType: initialAutocompletePropertyType(),
  searchAutocompleteZones: initialAutocompleteZones(),
};

const mutations = {
  resetSearch: (state) => (state.search = initialSearch()),
  resetSearchAutocompleteEmployee: (state) =>
    (state.searchAutocompleteEmployee = initialAutocompleteEmployee()),
  resetSearchAutocompletePropertyType: (state) =>
    (state.searchAutocompletePropertyType = initialAutocompletePropertyType()),
  resetSearchAutocompleteZones: (state) =>
    (state.searchAutocompleteZones = initialAutocompleteZones()),
  setInProcess: (state, inProcess) => (state.inProcess = inProcess),
  setFetchController: (state, controller) =>
    (state.fetchController = controller),
  setProperties: (state, properties) => (state.properties = properties),
  setPagination: (state, pagination) => (state.pagination = pagination),
  setSearch: (state, search) =>
    (state.search = helpers.deepClone({ ...state.search, ...search })),
  setSearchAutocompleteEmployee: (state, autocompleteEmployee) =>
    (state.searchAutocompleteEmployee = {
      ...state.searchAutocompleteEmployee,
      ...autocompleteEmployee,
    }),
  setSearchAutocompletePropertyType: (state, autocompletePropertyType) =>
    (state.searchAutocompletePropertyType = {
      ...state.searchAutocompletePropertyType,
      ...autocompletePropertyType,
    }),
  setSearchAutocompleteZones: (state, autocompleteZones) =>
    (state.searchAutocompleteZones = {
      ...state.searchAutocompleteZones,
      ...autocompleteZones,
    }),
};

const actions = {
  async fetchProperties({ commit, state }) {
    commit("setInProcess", true);
    const { call, controller } = getProperties(state.search);
    commit("setFetchController", controller);

    const response = await call;
    if (response?.status === 200) {
      const { properties, pagination } = response.data;
      commit("setProperties", properties);
      commit("setPagination", pagination);
    }
    commit("setInProcess", false);
  },

  changePage({ commit, dispatch }, payload) {
    const { page } = payload;
    commit("setSearch", { page });
    dispatch("fetchProperties");
  },

  onSort({ commit, dispatch }, payload) {
    const { sortBy } = payload;
    commit("setSearch", { page: 1, sortBy });
    dispatch("fetchProperties");
  },

  fillSearchAndAutocompletes({ commit }, payload) {
    const { autocompletePropertyType, autocompleteZones, search } =
      helpers.deepClone(payload);

    commit("setSearchAutocompletePropertyType", autocompletePropertyType);
    commit("setSearchAutocompleteZones", autocompleteZones);
    commit("setSearch", search);
  },

  resetSearchAndAutocompletes({ commit }) {
    commit("resetSearch");
    commit("resetSearchAutocompleteEmployee");
    commit("resetSearchAutocompletePropertyType");
    commit("resetSearchAutocompleteZones");
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
