import { operationTypeAdapter } from "@/services/adapters";

export function operationTypeTransformResponse(data) {
  data = JSON.parse(data);
  if (data?.operationType)
    data.operationType = operationTypeAdapter(data.operationType);
  if (data?.operationTypes)
    data.operationTypes = data.operationTypes.map((operationType) =>
      operationTypeAdapter(operationType)
    );
  return data;
}
