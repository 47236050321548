import { potentialCustomerAdapter } from "@/services/adapters";

export function potentialCustomerTransformResponse(data) {
  data = JSON.parse(data);
  if (data?.potentialCustomer)
    data.potentialCustomer = potentialCustomerAdapter(data.potentialCustomer);
  if (data?.potentialCustomers)
    data.potentialCustomers = data.potentialCustomers.map((potentialCustomer) =>
      potentialCustomerAdapter(potentialCustomer)
    );
  return data;
}

export function potentialCustomerTransformRequest(data) {
  return JSON.stringify({
    name: data.name,
    cell_numbers: data.cellNumbers,
    payment_type: data.paymentType,
    budget: data.budget,
    currency: data.currency,
    notes: data.notes,
    features: data.features,
    operation_type_id: data.operationTypeId,
    property_type_id: data.propertyTypeId,
    zones: data.zones,
  });
}
