import { branchOfficeAdapter, userAdapter } from "@/services/adapters";

export const employeeAdapter = (employee) => {
  return {
    id: employee.id,
    identityCard: employee.identity_card,
    name: employee.name,
    firstSurname: employee.first_surname,
    secondSurname: employee.second_surname,
    phone: employee.phone,
    branchOffice:
      employee?.branch_office && branchOfficeAdapter(employee.branch_office),
    user: employee?.user && userAdapter(employee.user),
  };
};
