import { currentUser, login, logout } from "@/services";
import { enums } from "@/utilities";
import router from "@/router";

const state = {
  inProcess: false,
  isAuth: false,
  token: null,
  user: {},
};

const mutations = {
  setInProcess: (state, inProcess) => (state.inProcess = inProcess),
  setIsAuth: (state, isAuth) => (state.isAuth = isAuth),
  setToken: (state, token) => (state.token = token),
  setUser: (state, user) => (state.user = { ...state.user, ...user }),
  resetIsAuth: (state) => (state.isAuth = false),
  resetToken: (state) => (state.token = null),
  resetUser: (state) => (state.user = {}),
};

const actions = {
  login: async ({ commit }, payload) => {
    const { credentials } = payload;

    commit("setInProcess", true);
    const loginResponse = await login(credentials);
    if (loginResponse?.status === 201) {
      commit("setToken", loginResponse.data.token);
      const authenticatedResponse = await currentUser();
      if (authenticatedResponse?.status === 200) {
        commit("setIsAuth", true);
        commit("setUser", authenticatedResponse.data.user);
        router.push({ name: "dashboard-home" });
      }
    }
    commit("setInProcess", false);
  },

  logout: async ({ commit }) => {
    commit("setInProcess", true);
    const response = await logout();
    if (response?.status === 200) {
      window.localStorage.clear();
      router.go(0);
    }
    commit("setInProcess", false);
  },

  resetAuthentication: ({ commit }) => {
    commit("resetIsAuth");
    commit("resetToken");
    commit("resetUser");
  },
};

const getters = {
  photo(state) {
    return state.user?.photo ?? require("@/assets/images/default-avatar.svg");
  },
  fullname(state) {
    const { name, firstSurname, secondSurname } = state.user;
    return `${name ?? ""} ${firstSurname ?? ""} ${secondSurname ?? ""}`;
  },
  roleName(state) {
    return state.user?.isAdmin == null
      ? ""
      : enums.roles.nameOf(state.user?.isAdmin);
  },
  isAdmin(state) {
    return state.user.isAdmin;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
