import {
  branchOfficeAdapter,
  employeeAdapter,
  operationTypeAdapter,
  propertyTypeAdapter,
  zoneAdapter,
} from "@/services/adapters";

export const propertyAdapter = (property) => {
  return {
    address: property.address,
    cellNumbers: property.cell_numbers,
    code: property.code,
    commission: Number(property.commission),
    createdAt: property.created_at,
    currency: property.currency,
    deletedAt: property.deleted_at,
    description: property.description,
    economicActivity: property.economic_activity,
    features: property.features,
    guaranteePrice: property.guarantee_price,
    id: property.id,
    images: property.images,
    notes: property.notes,
    ownerName: property.owner_name,
    ownerPrice: property.owner_price,
    paymentType: property.payment_type,
    realEstatePrice: property.real_estate_price,
    referenceArea: property.reference_area,
    renovatedAt: property.renovated_at,
    status: property.status,
    updatedAt: property.updated_at,
    videoUrl: property.video_url,
    branchOffice:
      property?.branch_office && branchOfficeAdapter(property.branch_office),
    employee: property?.employee && employeeAdapter(property.employee),
    operationType:
      property?.operation_type && operationTypeAdapter(property.operation_type),
    propertyType:
      property?.property_type && propertyTypeAdapter(property.property_type),
    zone: property?.zone && zoneAdapter(property.zone),
  };
};
