<template>
  <main id="app">
    <transition name="slide-left" mode="out-in">
      <router-view></router-view>
    </transition>
  </main>
</template>

<script>
export default {
  name: "MainView",
};
</script>

<style scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: opacity 100ms ease-out, transform 150ms ease;
}
.slide-left-enter,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-1rem);
}
</style>
