import { branchOfficeTransformResponse as transformResponse } from "./transforms";
import Http from "./Http";
const PREFIX = "branch-offices";

export function getBranchOffices(config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal, transformResponse };
  const call = Http.get(PREFIX, config);

  return { call, controller };
}

export function createBranchOffice(formData, config) {
  return Http.post(PREFIX, formData, config);
}

export function updateBranchOffice(id, formData, config) {
  return Http.put(`${PREFIX}/${id}`, formData, config);
}

export function deleteBranchOffice(id) {
  return Http.delete(`${PREFIX}/${id}`);
}

export function restoreBranchOffice(id) {
  return Http.patch(`${PREFIX}/${id}`);
}

export function checkBranchOfficeNameIsUnique(config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal };
  const call = Http.get(`${PREFIX}/name-unique`, config);

  return { call, controller };
}
