import Http from "./Http";
const PREFIX = "authentications";

export function login(credentials) {
  return Http.post(`${PREFIX}/login`, credentials);
}

export function logout() {
  return Http.post(`${PREFIX}/logout`);
}

export function currentUser(config) {
  return Http.get(`${PREFIX}/current-user`, config);
}
