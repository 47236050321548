import VuexPersistence from "vuex-persist";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

const storage = {
  getItem: (key) => ls.get(key),
  setItem: (key, value) => ls.set(key, value),
};

const reducer = (state) => ({
  potentialCustomerStore: {
    search: state.potentialCustomerStore.search,
    searchAutocompletePropertyType:
      state.potentialCustomerStore.searchAutocompletePropertyType,
    searchAutocompleteZones:
      state.potentialCustomerStore.searchAutocompleteZones,
  },
});

function filter(mutation) {
  return [
    "potentialCustomerStore/setSearch",
    "potentialCustomerStore/setSearchAutocompletePropertyType",
    "potentialCustomerStore/setSearchAutocompleteZones",
    "potentialCustomerStore/setPotentialCustomers",
  ].includes(mutation.type);
}

const potentialCustomerPersistence = new VuexPersistence({
  key: "potentialCustomer",
  storage,
  reducer,
  filter,
});

export default potentialCustomerPersistence.plugin;
