import router from "@/router";
import { notifications } from "@/utilities";

export function responseInterceptor(response) {
  return response;
}

export function responseInterceptorError(error) {
  if (error?.code === "ERR_CANCELED") return;
  if (
    error.config?.url === "authentications/login" &&
    error.response?.status === 422
  ) {
    notifications.error({ message: error.response.data.message });
    return;
  }
  if (error.response?.status === 401) {
    window.localStorage.clear();
    router.go(0);
    return;
  }
  notifications.error({
    message:
      "problemas con el servidor, si el problema persiste solicite soporte.",
  });
}
