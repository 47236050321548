import VuexPersistence from "vuex-persist";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

const storage = {
  getItem: (key) => ls.get(key),
  setItem: (key, value) => ls.set(key, value),
};

const reducer = (state) => ({
  propertyStore: {
    search: state.propertyStore.search,
    searchAutocompleteEmployee: state.propertyStore.searchAutocompleteEmployee,
    searchAutocompletePropertyType:
      state.propertyStore.searchAutocompletePropertyType,
    searchAutocompleteZones: state.propertyStore.searchAutocompleteZones,
  },
});

function filter(mutation) {
  return [
    "propertyStore/setSearch",
    "propertyStore/setSearchAutocompleteEmployee",
    "propertyStore/setSearchAutocompletePropertyType",
    "propertyStore/setSearchAutocompleteZones",
    "propertyStore/setProperties",
  ].includes(mutation.type);
}

const propertyPersistence = new VuexPersistence({
  key: "property",
  storage,
  reducer,
  filter,
});

export default propertyPersistence.plugin;
