import { profileTransformResponse as transformResponse } from "./transforms/profile-tranforms";
import Http from "./Http";
const PREFIX = "profiles";

export function getProfile(config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal, transformResponse };
  const call = Http.get(PREFIX, config);

  return { call, controller };
}

export function changePhotoUserProfile(formData, config) {
  config = {
    ...config,
    headers: { "Content-Type": "multipart/form-data" },
  };
  return Http.post(`${PREFIX}/change-photo`, formData, config);
}

export function changePasswordUserProfile(formData, config) {
  return Http.post(`${PREFIX}/change-password`, formData, config);
}
