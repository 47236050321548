import {
	propertyTransformResponse as transformResponse,
	propertyTransformRequest as transformRequest,
	employeeTransformResponse,
} from './transforms'
import Http from './Http'
const PREFIX = 'properties'

export function getProperty(id, config) {
	const controller = new AbortController()
	config = { ...config, transformResponse, signal: controller.signal }
	const call = Http.get(`${PREFIX}/${id}`, config)

	return { call, controller }
}

export function getProperties(search, config) {
	const controller = new AbortController()
	config = { ...config, transformResponse, signal: controller.signal }
	const call = Http.post(`${PREFIX}/search`, search, config)

	return { call, controller }
}

export function createProperty(formData, config) {
	config = { ...config, transformRequest }
	return Http.post(PREFIX, formData, config)
}

export function updateProperty(id, formData, config) {
	config = { ...config, transformRequest }
	return Http.put(`${PREFIX}/${id}`, formData, config)
}

export function deleteProperty(id, config) {
	config = { ...config, transformResponse }
	return Http.delete(`${PREFIX}/${id}`, config)
}

export function restoreProperty(id, formData, config) {
	config = { ...config, transformResponse }
	return Http.patch(`${PREFIX}/${id}/restore`, formData, config)
}

export function uploadPropertyImages(id, formData, config) {
	config = {
		...config,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	}
	return Http.post(`${PREFIX}/${id}/upload-images`, formData, config)
}

export function deletePropertyImage(id, imageIndex, config) {
	return Http.delete(`${PREFIX}/${id}/image/${imageIndex}`, config)
}

export function assignPropertyEmployee(id, formData, config) {
	const transformRequest = (data) => JSON.stringify({ employee_id: data.employeeId })
	config = {
		...config,
		transformRequest,
		transformResponse: employeeTransformResponse,
	}
	return Http.patch(`${PREFIX}/${id}/assign-employee`, formData, config)
}

export function assignMeProperty(id, formData, config) {
	config = { ...config, transformResponse: employeeTransformResponse }
	return Http.patch(`${PREFIX}/${id}/assign-me`, formData, config)
}
