const scrollBehavior = (to, from, savedPosition) => {
  const options = { behavior: "smooth" };

  if (savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(savedPosition);
      }, 375);
    });
  }

  if (to.hash) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          ...options,
          offset: { y: 50 },
          selector: to.hash,
        });
      }, 375);
    });
  }

  return { x: 0, y: 0, ...options };
};

export default scrollBehavior;
