import {
  economicActivities,
  statuses,
  currencies,
  paymentTypes,
  propertyStatuses,
} from "./enums";

export const CURRENCY_OPTIONS = [
  {
    id: currencies.BOB,
    name: currencies.nameOf(currencies.BOB),
  },
  {
    id: currencies.USD,
    name: currencies.nameOf(currencies.USD),
  },
];

export const ECONOMIC_ACTIVITY_OPTIONS = [
  {
    id: economicActivities.PORCENTAJE,
    name: economicActivities.nameOf(economicActivities.PORCENTAJE),
  },
  {
    id: economicActivities.EXCEDENTE,
    name: economicActivities.nameOf(economicActivities.EXCEDENTE),
  },
  {
    id: economicActivities.HONORARIO_FIJO,
    name: economicActivities.nameOf(economicActivities.HONORARIO_FIJO),
  },
];

export const PAYMENT_TYPE_OPTIONS = [
  { id: paymentTypes.COUNTED, name: paymentTypes.nameOf(paymentTypes.COUNTED) },
  {
    id: paymentTypes.FINANCING,
    name: paymentTypes.nameOf(paymentTypes.FINANCING),
  },
  {
    id: paymentTypes.DIRECT_CREDIT,
    name: paymentTypes.nameOf(paymentTypes.DIRECT_CREDIT),
  },
];

export const STATUS_OPTIONS = [
  { id: statuses.ACTIVE, name: statuses.nameOf(statuses.ACTIVE) },
  {
    id: statuses.INACTIVE,
    name: statuses.nameOf(statuses.INACTIVE),
  },
];

export const PROPERTY_STATUS_OPTIONS = [
  {
    id: propertyStatuses.AVAILABLE,
    name: propertyStatuses.nameOf(propertyStatuses.AVAILABLE),
  },
  {
    id: propertyStatuses.IN_ELIMINATION,
    name: propertyStatuses.nameOf(propertyStatuses.IN_ELIMINATION),
  },
  {
    id: propertyStatuses.SOLD_OUT,
    name: propertyStatuses.nameOf(propertyStatuses.SOLD_OUT),
  },
];
