import {
	requestInterceptor,
	requestInterceptorError,
	responseInterceptor,
	responseInterceptorError,
} from './interceptors'
import axios from 'axios'

const config = {
	baseURL: 'https://server.openhouseinmo.com/api/',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	withCredentials: true,
	timeout: 60 * 4 * 1000,
}

const instance = axios.create(config)
instance.interceptors.request.use(requestInterceptor, requestInterceptorError)
instance.interceptors.response.use(responseInterceptor, responseInterceptorError)

export default instance
