import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'buefy/dist/buefy.css'
import './assets/base.css'
import '@mdi/font/css/materialdesignicons.css'

import global from './global'
Vue.use(global)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
