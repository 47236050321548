import { getPotentialCustomers } from "@/services";
import { enums, helpers } from "@/utilities";

const initialFormData = () => ({
  budget: null,
  cellNumbers: "",
  currency: enums.currencies.BOB,
  features: {},
  name: "",
  notes: [],
  operationTypeId: null,
  paymentType: enums.paymentTypes.COUNTED,
  propertyTypeId: null,
  zones: [],
});
const initialSearch = () => ({
  code: "",
  currency: null,
  name: "",
  operationTypeId: null,
  page: 1,
  paymentTypes: [
    enums.paymentTypes.COUNTED,
    enums.paymentTypes.FINANCING,
    enums.paymentTypes.DIRECT_CREDIT,
  ],
  priceMax: null,
  priceMin: null,
  propertyTypeId: null,
  sortBy: ["id", "desc"],
  zones: [],
});
const initialAutocompletePropertyType = () => ({
  form: [],
  name: "",
});
const initialAutocompleteZones = () => ({
  zones: [],
});

const state = {
  fetchController: null,
  formData: initialFormData(),
  formDataAutocompletePropertyType: initialAutocompletePropertyType(),
  formDataAutocompleteZones: initialAutocompleteZones(),
  inProcess: false,
  pagination: {},
  potentialCustomers: [],
  search: initialSearch(),
  searchAutocompletePropertyType: initialAutocompletePropertyType(),
  searchAutocompleteZones: initialAutocompleteZones(),
};

const mutations = {
  resetFormData: (state) => (state.formData = initialFormData()),
  resetFormDataAutocompletePropertyType: (state) =>
    (state.formDataAutocompletePropertyType =
      initialAutocompletePropertyType()),
  resetFormDataAutocompleteZones: (state) =>
    (state.formDataAutocompleteZones = initialAutocompleteZones()),
  resetSearch: (state) => (state.search = initialSearch()),
  resetSearchAutocompletePropertyType: (state) =>
    (state.searchAutocompletePropertyType = initialAutocompletePropertyType()),
  resetSearchAutocompleteZones: (state) =>
    (state.searchAutocompleteZones = initialAutocompleteZones()),
  setFetchController: (state, controller) =>
    (state.fetchController = controller),
  setFormData: (state, formData) =>
    (state.formData = { ...state.formData, ...formData }),
  setFormDataAutocompletePropertyType: (state, autocompletePropertyType) =>
    (state.formDataAutocompletePropertyType = autocompletePropertyType),
  setFormDataAutocompleteZones: (state, autocompleteZones) =>
    (state.formDataAutocompleteZones = autocompleteZones),
  setInProcess: (state, inProcess) => (state.inProcess = inProcess),
  setPagination: (state, pagination) => (state.pagination = pagination),
  setPotentialCustomers: (state, potentialCustomers) =>
    (state.potentialCustomers = potentialCustomers),
  setSearch: (state, search) =>
    (state.search = helpers.deepClone({ ...state.search, ...search })),
  setSearchAutocompletePropertyType: (state, autocompletePropertyType) =>
    (state.searchAutocompletePropertyType = {
      ...state.searchAutocompletePropertyType,
      ...autocompletePropertyType,
    }),
  setSearchAutocompleteZones: (state, autocompleteZones) =>
    (state.searchAutocompleteZones = {
      ...state.searchAutocompleteZones,
      ...autocompleteZones,
    }),
};

const actions = {
  async fetchPotentialCustomers({ commit, state }) {
    commit("setInProcess", true);
    const { call, controller } = getPotentialCustomers(state.search);
    commit("setFetchController", controller);

    const response = await call;
    if (response?.status === 200) {
      const { potentialCustomers, pagination } = response.data;
      commit("setPotentialCustomers", potentialCustomers);
      commit("setPagination", pagination);
    }
    commit("setInProcess", false);
  },

  changePage({ commit, dispatch }, payload) {
    const { page } = payload;
    commit("setSearch", { page });
    dispatch("fetchPotentialCustomers");
  },

  onSort({ commit, dispatch }, payload) {
    const { sortBy } = payload;
    commit("setSearch", { page: 1, sortBy });
    dispatch("fetchPotentialCustomers");
  },

  fillFormDataAndAutocompletes({ commit, dispatch }, payload) {
    const { autocompletePropertyType, autocompleteZones, formData } =
      helpers.deepClone(payload);

    dispatch("resetFormDataAndAutocompletes");
    commit("setFormDataAutocompletePropertyType", autocompletePropertyType);
    commit("setFormDataAutocompleteZones", autocompleteZones);
    commit("setFormData", formData);
  },

  fillSearchAndAutocompletes({ commit, dispatch }, payload) {
    const { autocompletePropertyType, autocompleteZones, search } =
      helpers.deepClone(payload);

    dispatch("resetSearchAndAutocompletes");
    commit("setSearchAutocompletePropertyType", autocompletePropertyType);
    commit("setSearchAutocompleteZones", autocompleteZones);
    commit("setSearch", search);
  },

  resetFormDataAndAutocompletes: ({ commit }) => {
    commit("resetFormData");
    commit("resetFormDataAutocompletePropertyType");
    commit("resetFormDataAutocompleteZones");
  },

  resetSearchAndAutocompletes({ commit }) {
    commit("resetSearch");
    commit("resetSearchAutocompletePropertyType");
    commit("resetSearchAutocompleteZones");
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
