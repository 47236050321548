import Http from "./Http";
const PREFIX = "users";

export function activateUser(id) {
  return Http.patch(`${PREFIX}/${id}/activate`);
}

export function unsubscribeUser(id) {
  return Http.patch(`${PREFIX}/${id}/unsubscribe`);
}

export function checkUserEmailIsUnique(config) {
  const controller = new AbortController();
  config = { ...config, signal: controller.signal };
  const call = Http.get(`${PREFIX}/email-unique`, config);

  return { call, controller };
}
