import MainView from '@/views/MainView.vue'
export const webSitieRoutes = {
	path: '/',
	component: MainView,
	children: [
		{
			path: '',
			name: 'main-home',
			component: () => import('@/views/MainHome.vue'),
		},
		{
			path: 'inmuebles',
			name: 'properties',
			component: () => import('@/views/PropertiesView.vue'),
		},
	],
}
