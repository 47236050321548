import {
  branchOfficeAdapter,
  employeeAdapter,
  operationTypeAdapter,
  propertyTypeAdapter,
} from "@/services/adapters";

export const potentialCustomerAdapter = (potentialCustomer) => {
  return {
    budget: potentialCustomer.budget,
    cellNumbers: potentialCustomer.cell_numbers,
    code: potentialCustomer.code,
    createdAt: potentialCustomer.created_at,
    currency: potentialCustomer.currency,
    features: potentialCustomer.features,
    id: potentialCustomer.id,
    name: potentialCustomer.name,
    notes: potentialCustomer.notes,
    paymentType: potentialCustomer.payment_type,
    updatedAt: potentialCustomer.updated_at,
    zones: potentialCustomer.zones,
    branchOffice:
      potentialCustomer.branch_office &&
      branchOfficeAdapter(potentialCustomer.branch_office),
    employee:
      potentialCustomer.employee && employeeAdapter(potentialCustomer.employee),
    operationType:
      potentialCustomer.operation_type &&
      operationTypeAdapter(potentialCustomer.operation_type),
    propertyType:
      potentialCustomer.property_type &&
      propertyTypeAdapter(potentialCustomer.property_type),
  };
};
