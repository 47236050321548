import { getBranchOffices, getOperationTypes } from "@/services";
import { helpers, notifications } from "@/utilities";

const state = {
  branchOffices: [],
  inProcess: false,
  operationTypes: [],
  sidebarDashboardIsActive: false,
};

const mutations = {
  setBranchOffices: (state, branchOffices) =>
    (state.branchOffices = branchOffices),
  setInProcess: (state, inProcess) => (state.inProcess = inProcess),
  setOperationTypes: (state, operationTypes) =>
    (state.operationTypes = operationTypes),
  setSidebarDashboardIsActive: (state, isActive) =>
    (state.sidebarDashboardIsActive = isActive),
};

const actions = {
  toggleSidebarDashboard({ commit, state }) {
    commit("setSidebarDashboardIsActive", !state.sidebarDashboardIsActive);
    if (state.sidebarDashboardIsActive) {
      document.documentElement.classList.add("is-clipped");
      return;
    }
    document.documentElement.classList.remove("is-clipped");
  },

  async fetchBranchOffices({ commit }) {
    const { call } = getBranchOffices();
    const response = await call;
    if (response?.status === 200)
      commit("setBranchOffices", response.data.branchOffices);
    else
      notifications.error({
        message: "problems loading branch offices in resource",
      });
  },

  async fetchOperationTypes({ commit }) {
    const { call } = getOperationTypes();
    const response = await call;
    if (response?.status === 200)
      commit("setOperationTypes", response.data.operationTypes);
    else
      notifications.error({
        message: "problems loading operation types in resource",
      });
  },

  async loadResources({ dispatch, state }) {
    if (helpers.isEmpty(state.branchOffices)) dispatch("fetchBranchOffices");
    if (helpers.isEmpty(state.operationTypes)) dispatch("fetchOperationTypes");
  },
};

const getters = {
  saleId(state) {
    if (helpers.isEmpty(state.operationTypes)) return null;
    const operationType = state.operationTypes.find((operationType) => {
      return operationType.name === "Venta";
    });

    return operationType.id;
  },

  rentalId(state) {
    if (helpers.isEmpty(state.operationTypes)) return null;
    const operationType = state.operationTypes.find((operationType) => {
      return operationType.name === "Alquiler";
    });

    return operationType.id;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
