export const currencies = Object.freeze({
  nameOf: (currency) => {
    const currencyNames = ["", "BOB", "USD"];
    return currencyNames[currency];
  },
  BOB: 1,
  USD: 2,
});

export const economicActivities = Object.freeze({
  nameOf: (economicActivity) => {
    const economicActivityNames = [
      "",
      "Defecto",
      "Porcentaje",
      "Excedente",
      "Honorario fijo",
    ];
    return economicActivityNames[economicActivity];
  },
  DEFAULT: 1,
  PORCENTAJE: 2,
  EXCEDENTE: 3,
  HONORARIO_FIJO: 4,
});

export const fieldTypes = Object.freeze({
  nameOf: (fieldType) => {
    const fieldTypeNames = [
      "",
      "tipear",
      "selectivo",
      "deslizador numeral",
      "interruptor si-no",
    ];
    return fieldTypeNames[fieldType];
  },
  TYPING: 1,
  SELECTIVE: 2,
  SLIDER: 3,
  SWITCH: 4,
});

export const statuses = Object.freeze({
  nameOf: (status) => (status ? "Activo" : "Inactivo"),
  INACTIVE: false,
  ACTIVE: true,
});

export const propertyStatuses = Object.freeze({
  nameOf: (status) => {
    const statusNames = ["En eliminacion", "Disponible", "Vendido"];
    return statusNames[status];
  },
  IN_ELIMINATION: 0,
  AVAILABLE: 1,
  SOLD_OUT: 2,
});

export const roles = Object.freeze({
  nameOf: (role) => (role ? "Administrador" : "Agente inmobiliario"),
  REAL_ESTATE_AGENT: 0,
  ADMINISTRATOR: 1,
});

export const paymentTypes = Object.freeze({
  nameOf: (paymentType) => {
    const paymentTypeNames = [
      "",
      "Contado",
      "Financiamiento",
      "Credito directo",
    ];
    return paymentTypeNames[paymentType];
  },
  COUNTED: 1,
  FINANCING: 2,
  DIRECT_CREDIT: 3,
});
