import store from "@/store";

export const authMiddleware = (to, from, next) => {
  const isAuth = store.state.authStore.isAuth;
  const isAdmin = store.state.authStore.user.isAdmin;

  if (to.matched.some((route) => route.meta.requireAuth)) {
    if (!isAuth) return next({ name: "auth" });
  }
  if (to.matched.some((route) => route.meta.requireBeAdmin)) {
    if (!isAdmin) return next(false);
  }
  if (isAuth && to.name === "auth") {
    return next("/dashboard");
  }

  next();
};
