import { employeeAdapter } from "@/services/adapters";

export function employeeTransformResponse(data) {
  data = JSON.parse(data);
  if (data?.employee) data.employee = employeeAdapter(data.employee);
  if (data?.employees)
    data.employees = data.employees.map((employee) =>
      employeeAdapter(employee)
    );
  return data;
}

export function employeeUserTransformRequest(data) {
  const { employee, user } = data;
  return JSON.stringify({
    employee: {
      branch_office_id: employee.branchOfficeId,
      first_surname: employee.firstSurname,
      identity_card: employee.identityCard,
      name: employee.name,
      phone: employee.phone,
      second_surname: employee.secondSurname,
    },
    user: {
      email: user.email,
      password: user.password,
      password_confirmation: user.passwordConfirmation,
    },
  });
}
